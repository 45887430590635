<template>
  <div v-if="categoryList && categoryList.length > 0" class="category-container">
    <div class="title">
      <h1></h1>
      <f7-link href="/shop/category/list/">
        {{ $t.getTranslation("LBL_VIEW_ALL") }}
        <font-awesome-icon :icon="['fas', 'chevron-right']" fixed-width />
      </f7-link>
    </div>

    <div class="container">
      <f7-swiper v-if="categoryList?.length > 4" :slides-per-view="'auto'" :space-between="0" navigation loop :autoplay="{ delay: 5000 }">
        <f7-swiper-slide v-for="category in categoryList" :key="'cat_' + category.CategoryId">
          <CategoryCardComponent :data="category" :supplierCode="supplierCode" />
        </f7-swiper-slide>
      </f7-swiper>

      <div v-else class="category-card-container">
        <div v-for="category in categoryList" :key="'cat_' + category.CategoryId">
          <CategoryCardComponent :data="category" :supplierCode="supplierCode" />
        </div>
      </div>

      <!-- <div v-if="divider && categoryList?.length > 0" class="linedivider"></div> -->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, defineAsyncComponent, computed } from "vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";

//import CategoryCardComponent from "@/components/cards/CategoryCardComponent.vue";

export default defineComponent({
  name: "CategoryListComponent",
  components: {
    CategoryCardComponent: defineAsyncComponent(() => import(/* webpackChunkName: "category-card" */ /* webpackMode: "lazy" */ "@/components/cards/CategoryCardComponent.vue")),
  },
  props: {
    type: String,
    divider: Boolean,
    size: { type: Number, default: 6 },
    supplierCode: String,
    mode: String,
  },
  setup(props) {
    const store = useStore();

    const languageCode = computed(() => store.getters["translation/getLanguage"]);

    let page = 1;
    let lastPage = 1;

    const searchValue = ref("");
    const categoryList = ref([]);

    const getData = async (reset = false) => {
      if (reset) {
        page = 1;
        lastPage = 1;
        categoryList.value = [];
      }

      let getParams = {
        Type: props.type,
        page: page,
        size: props.size,
        SearchField: "MULTIPLE",
        SearchType: "LIKE",
        SearchValue: searchValue.value,
        LanguageCode: languageCode.value,
      };

      if (props.mode) {
        getParams["mode"] = props.mode;
      }

      let ret = await get("/category/list", getParams);

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          categoryList.value.push(item);
        }

        lastPage = ret?.lastPage;
        page++;
      }
    };

    const loadMore = () => {
      return lastPage >= page ? getData() : false;
    };

    const search = (data) => {
      searchValue.value = data;
      categoryList.value = [];
      page = 1;

      getData();
    };

    onMounted(() => {
      getData();
    });

    return { categoryList, loadMore, search, getData };
  },
});
</script>

<style>
.category-container .linedivider {
  width: calc(100% - 10px);
}
</style>
